import {
  Box,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import Balancer from 'react-wrap-balancer';

interface CoverPhotoProps {
  children?: React.ReactNode;
  url: string;
  heading?: string | null;
  subHeading?: string | null;
  bgMode?: 'contain' | 'cover';
}

function CoverPhoto({ url, heading, subHeading, bgMode = 'cover', children }: CoverPhotoProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        cursor='pointer'
        h='xs'
        p={4}
        pos='relative'
        textAlign='center'
        w='100%'
        onClick={onOpen}
      >
        <Box
          background={`url('${url}')`}
          backgroundPosition='top center'
          backgroundSize='cover'
          filter='blur(4px)'
          h='100%'
          left={0}
          pos='absolute'
          top={0}
          w='100%'
        />
        <Box
          background={`url(${url})`}
          backgroundPosition='center'
          backgroundRepeat='no-repeat'
          backgroundSize={bgMode}
          h='100%'
          left={0}
          pos='absolute'
          top={0}
          w='100%'
        />
        {heading && (
          <>
            <Box
              bgColor='gray.700'
              h='100%'
              left={0}
              opacity={0.75}
              pos='absolute'
              top={0}
              w='100%'
            />
            <Box
              left='50%'
              pos='absolute'
              textColor='white'
              top='50%'
              transform='translate(-50%,-50%)'
            >
              {subHeading && (
                <Heading
                  fontFamily='body'
                  fontSize='md'
                  letterSpacing={6}
                  mb={4}
                  textTransform='uppercase'
                >
                  <Balancer>{subHeading}</Balancer>
                </Heading>
              )}
              <Heading fontFamily='heading' fontSize='2xl' fontStyle='italic' fontWeight='normal'>
                <Balancer>{heading}</Balancer>
              </Heading>
            </Box>
          </>
        )}
        {children && children}
      </Flex>
      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Image alt={heading ?? ''} src={url} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CoverPhoto;
