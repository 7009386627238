import { Flex, FlexProps, Heading, Image, Stack, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { TopicDocument } from 'prismicio-types';

import Paper from './Paper';
import { richTextToString } from './RichText';

interface Props extends FlexProps {
  title: string;
  subtitle: string;
  description: string;
  slug: string;
  imageUrl: string;
}

function Card({ title, subtitle, description, slug, imageUrl, ...props }: Props) {
  return (
    <Link href={`/info?topic=${slug}`}>
      <Flex
        _active={{ bgColor: 'gray.100' }}
        _hover={{ bgColor: 'gray.50' }}
        cursor='pointer'
        flexDir='column'
        p={3}
        {...props}
      >
        <Flex alignItems={'center'} mb={1}>
          {imageUrl && <Image alt={title} h='8' mr={3} src={imageUrl} />}
          {title && <Heading fontFamily='body' fontSize='sm' mb={1}>{`${title}`}</Heading>}
        </Flex>
        <Text fontSize='xs'>
          {subtitle && `${subtitle}`}
          {description}
        </Text>
      </Flex>
    </Link>
  );
}

function TopicCards({ topics }: { topics: TopicDocument[] }) {
  return (
    <Paper borderBottomRightRadius={10} borderTopRightRadius={10} mb={6} p={3}>
      <Stack>
        {topics.map((t, i) => (
          <Card
            key={t.uid}
            borderTopRadius={i === 0 ? 0 : 5}
            description={richTextToString(t.data.short_description)}
            imageUrl={t.uid === 'hpa' ? `/vit.svg` : t.uid === 'ilc' ? `/yy.svg` : `/om.svg`}
            slug={t.uid}
            subtitle={richTextToString(t.data.subtitle)}
            title={richTextToString(t.data.title)}
          />
        ))}
      </Stack>
    </Paper>
  );
}

export default TopicCards;
