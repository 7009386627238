import TopicCards from '@/components/TopicCards';

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import { PrismicNextLink } from '@prismicio/next';
import NextLink from 'next/link';
import { AboutPageDocumentData, TopicDocument } from 'prismicio-types';
import { FaExclamationTriangle, FaFacebookF, FaInstagram } from 'react-icons/fa';
import Balancer from 'react-wrap-balancer';

import Paper from './Paper';

function Sidebar({
  topics,
  usefulLinks,
}: {
  topics?: TopicDocument[];
  usefulLinks?: AboutPageDocumentData['useful_links'];
}) {
  const isMobile = useBreakpointValue({ base: true, xl: false });
  return !isMobile ? (
    <Flex alignSelf='flex-start' as='aside' flexDir='column' ml={-1} mt='40%' width='280px'>
      {topics && <TopicCards topics={topics} />}
      {usefulLinks && (
        <Paper
          borderBottomRightRadius={10}
          borderTopRightRadius={10}
          flexDir='column'
          mb={6}
          px={4}
          py={6}
        >
          <Heading fontFamily='body' size='md'>
            Useful Links
          </Heading>
          <Heading fontFamily='body' fontWeight='500' mb={4} size='xs' textColor='gray'>
            Inspired by, with respect to my teachers
          </Heading>
          <Box px={2}>
            {usefulLinks.map((link, index) => (
              <Box
                key={`useful-link-${index}`}
                _hover={{ textDecoration: 'underline' }}
                color='blue.500'
                mb={1}
                py={1}
              >
                <PrismicNextLink field={link.url} target='_blank'>
                  <Balancer>{link.title}</Balancer>
                </PrismicNextLink>
              </Box>
            ))}
          </Box>
        </Paper>
      )}
      <Paper
        alignItems='center'
        borderBottomRightRadius={10}
        borderTopRightRadius={10}
        flexDirection='column'
      >
        <Flex alignItems='center' flexDir='column' p={2} width='100%'>
          <NextLink href='/safety' style={{ width: '100%' }}>
            <Button
              colorScheme='teal'
              leftIcon={<Icon as={FaExclamationTriangle} />}
              mb={2}
              variant='outline'
              width='full'
            >
              Safety information
            </Button>
          </NextLink>
          <ButtonGroup isAttached size='sm' variant='outline' width='full'>
            <Button
              as='a'
              colorScheme='facebook'
              href='https://facebook.com/circlework.training'
              leftIcon={<Icon as={FaFacebookF} />}
              target='_blank'
              width='50%'
            >
              Facebook
            </Button>
            <Button
              as='a'
              colorScheme='pink'
              href='https://instagram.com/circlework.training'
              leftIcon={<Icon as={FaInstagram} />}
              target='_blank'
              width='50%'
            >
              Instagram
            </Button>
          </ButtonGroup>
        </Flex>
        <Divider mb={2} />
        <Flex alignItems='flex-start' flexDir='column' pb={4} pt={2} px={6} width='100%'>
          <Heading as='h2' fontFamily='body' fontSize='sm' mb={1}>
            Contact
          </Heading>
          <Flex
            _hover={{ color: 'blue.500', textDecoration: 'underline' }}
            alignItems='center'
            fontSize='sm'
            pl={1}
          >
            <Link href='mailto:chris@circlework.training'>chris@circlework.training</Link>
            {/* <IconButton aria-label='Copy' icon={<Icon as={FaCopy} />} size='xs' variant='ghost' /> */}
          </Flex>
          <Flex alignItems='center' pl={1}>
            <Link href='tel:+61484082107'>0484 082 107</Link>
          </Flex>
        </Flex>
      </Paper>
    </Flex>
  ) : null;
}

export default Sidebar;
